<template>
    <div class="WorkspaceKnowledge">
      <div class="WorkspaceKnowledge__akb_settings">
        <div class="section">
          <div class="section_part">
            <div class="label">Knowledge Base Model</div>
            <div class="body">
              <v-autocomplete
                  :disabled="!canEdit()"
                  style="width: 250px; border-radius: 15px;"
                  outlined
                  dense
                  :items="models"
                  v-model="selectedModel"
                  item-text="label"
                  item-value="value"
                  @change="
                      (value) => {
                        workspace.knowledgeBaseContextSize = modelConfigs[value].contextSize;
                        updateWorkspaceKBSettings();
                      }
                    "
              ></v-autocomplete>
            </div>
          </div>
          <div class="section_part">
            <div class="label">Model Temperature</div>
            <div class="body">
              <v-text-field type="number"
                            :disabled="!canEdit()"
                            v-model="workspace.knowledgeBaseTemperature"
                            outlined
                            dense
                            min="0"
                            step="0.1"
                            max=1
                            style="width: 70px; border-radius: 15px;"
                            @change="
                              () => {
                                updateWorkspaceKBSettings();
                              }
                            "
              ></v-text-field>
            </div>
          </div>
          <div class="section_part">
            <div class="label">Model Context Size</div>
            <div class="body">
              <v-text-field
                  type="number"
                  :disabled="!canEdit()"
                  v-model="workspace.knowledgeBaseContextSize"
                  outlined
                  dense
                  min="0"
                  :max="modelConfigs[workspace.knowledgeBaseAgentModel].contextSize"
                  style="width: 95px; border-radius: 15px;"
                  @change="
                              () => {
                                updateWorkspaceKBSettings();
                              }
                            "
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
      <div class="summary_input">
        <v-textarea
            :disabled="!canEdit()"
            class="TextAreaKBSummary"
            outlined
            dense
            :placeholder="'Write a summary of the information stored in the knowledge base'"
            hide-details="auto"
            novalidate
            :rows="3"
            v-model="knowledgeBaseSummary"
            @change="() => updateWorkspaceInfo()"
            label="Knowledge Base Summary"
        >
        </v-textarea>
      </div>
      <div class="inline">
        <div class="inlineItem">
          <v-row class="WorkspaceKnowledge__files">
            <div class="WorkspaceKnowledge__filesContainer" v-if="!isFilesLoading">
              <div v-for="(file, index) in files" :key="index" class="fileItem">
                <div style="position:relative; display: flex; justify-content: start">
                  <button @click.stop="onFileDelete(file.id)" class="closeButton">
                    <span>&times;</span>
                  </button>
                  <img v-if="file.type.includes('image')" :src="file.base64" class="image"/>
                  <div style="height: 80px" @click="openFileModal(file)">
                    <img v-if="!file.type.includes('image')" class="document" :src="`${publicPath}/icons/file-icon.svg`"/>
                  </div>
                  <span style="top: 0px">
                   {{ file.name }}
                 </span>
                </div>
              </div>
            </div>
            <v-progress-circular
                :size="25"
                :width="2"
                v-if="isFilesLoading"
                color="primary"
                indeterminate
                style="margin-left: 49%; margin-right: 49%"
            />
            <v-progress-linear striped :value="getFilesSize()" :height="10">
              <template v-slot:default="{ value }">
                <span style="font-size: 10pt">{{ Math.ceil(value) }}%</span>
              </template>
            </v-progress-linear>
          </v-row>
        </div>
        <div class="WorkspaceKnowledge__attach_buttons_container">
          <v-btn icon @click="attachGoogleDriveOpen = true">
            <google-drive-icon class="WorkspaceKnowledge__google_icon"/>
          </v-btn>
          <v-btn icon @click="attachOneDriveOpen = true">
            <one-drive-icon class="WorkspaceKnowledge__onedrive_icon" />
          </v-btn>
          <v-btn icon @click="openJsonEditModal = true">
            <v-icon> mdi-json </v-icon>
          </v-btn>
          <v-btn icon @click="refreshKnowledgeBase">
            <v-icon> mdi-update </v-icon>
          </v-btn>
        </div>
      </div>

      <v-dialog :value="openFile" persistent @click:outside="openFile = false">
        <v-card style="display: flex; flex-direction: column">
          <v-card-title class="text-h5"> {{ openedFile?.name }} </v-card-title>
          <v-row style="margin: 15px; overflow: auto; height: 50%">
            {{ openedFile?.rawText }}
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="openFile = false"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog :value="openJsonEditModal" persistent @click:outside="openJsonEditModal = false">
        <v-card style="display: flex; flex-direction: column; padding: 25px">
          <v-card-title class="text-h5"> JSON Knowledge </v-card-title>
          <div class="d-flex">
            <JsonEditorVue style="width: 100%; height: 100px; margin-bottom: 50px" :mode="'text'" v-model="jsonKnowledge"/>
          </div>
          <v-checkbox v-model="isNowSet" label="Include current date and time" @change="includeNow"></v-checkbox>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="openJsonEditModal = false"> Cancel </v-btn>
            <v-btn text @click="updateMemory"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <attach-from-google-drive :dialog="attachGoogleDriveOpen" @close="attachGoogleDriveOpen = false"/>
      <attach-from-one-drive :dialog="attachOneDriveOpen"  @close="attachOneDriveOpen = false"/>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import JsonEditorVue from "json-editor-vue";
import cloneDeep from "lodash/cloneDeep";
import constants from "@/util/constants";
import GoogleDriveIcon from '@/views/ai-workspaces/workspace/knowledge/icons/googledrive.vue';
import AttachFromGoogleDrive from "@/views/ai-workspaces/workspace/knowledge/attach/googledrive.vue";
import AttachFromOneDrive from "@/views/ai-workspaces/workspace/knowledge/attach/onedrive.vue";
import OneDriveIcon from "@/views/ai-workspaces/workspace/knowledge/icons/onedrive.vue";

const { mapGetters: workspacesGetters, mapActions: workspaceActions } = createNamespacedHelpers('aiWorkspace');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'AdvancedAgentKnowledge',
  components: {OneDriveIcon, AttachFromOneDrive, AttachFromGoogleDrive, JsonEditorVue, GoogleDriveIcon},
  props: {
    jsonKnowledge: {
      type: Object,
    }
  },
  data() {
    return {
      openFile: false,
      isNowSet: true,
      publicPath: constants.isDevelopment ? "" : "",
      filesSize: 0,
      openedFile: null,
      attachGoogleDriveOpen: false,
      attachOneDriveOpen: false,
      openJsonEditModal: false,
      knowledgeBaseSummary: '',
      selectedModel: undefined,
      modelConfigs: {
        "deepseek.r1-v1:0": {
          "contextSize": 8192,
          "temperature": 0.7
        },
        "anthropic.claude-3-5-sonnet-20240620-v1:0": {
          "contextSize": 200000,
          "temperature": 0.7,
        },
        "anthropic.claude-3-haiku-20240307-v1:0": {
          "contextSize": 48000,
          "temperature": 0.7,
        },
      },
      models: [
        {
          label: 'DeepSeek',
          value: 'deepseek.r1-v1:0',
        },
        {
          label: 'Claude V3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0',
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0',
        },
      ]
    }
  },
  watch: {
    workspace() {

      if(!this.jsonKnowledge) {
        this.jsonKnowledge = {
          now: true,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
      if(!this.jsonKnowledge.now) {
        this.isNowSet = false;
      } else {
        this.isNowSet = true;
      }

      if(this?.workspace?.id) {
        this.getWorkspaceKnowledge({ workspaceId: this.workspace.id });
        const kbAction = this.workspace?.general_actions?.find((act) => act?.action_type === 'internal_knowledge_action');
        if(kbAction) {
          this.knowledgeBaseSummary = kbAction?.settings?.summary;
        }
        this.selectedModel = this.workspace.knowledgeBaseAgentModel;
      }
    }
  },
  computed: {
    ...workspacesGetters({
      files: 'WORKSPACE_KNOWLEDGE_FILES',
      workspace: 'WORKSPACE',
      isFilesLoading: 'WORKSPACE_KNOWLEDGE_FILES_LOADING',
    }),
    ...customerGetters({
      user: 'USER',
    }),
  },
  methods: {
    ...workspaceActions(['getWorkspaceKnowledge','addWorkspaceKnowledge','deleteWorkspaceKnowledge', 'getKnowledgeRawText', 'refreshAdvancedWorkspaceKnowledge', 'updateWorkspace']),
    getFilesSize() {
      let size = 0;
      this.files.map((file) => {
        size += Number(file.size);
      })
      this.filesSize = size / 1024 / 1024;
      return size / 1024 / 10.24;
    },
    validateSize(input) {
      return this.filesSize + (input.size / 1024 / 1024) > 1;
    },
    refreshKnowledgeBase() {
      this.refreshAdvancedWorkspaceKnowledge({ workspaceId: this.workspace.id});
    },
    async openFileModal(file) {
      if(file.type.includes('google') && file.type.includes('document')) {
        window.open(`https://docs.google.com/document/d/${file.externalLocation}/edit`, '_blank');
        return;
      }
      if(file.type.includes('google') && file.type.includes('spreadsheet')) {
        window.open(`https://docs.google.com/spreadsheets/d/${file.externalLocation}/edit`, '_blank');
        return;
      }
      if(file.type.includes('onedrive')) {
        window.open(file.externalLink);
        return;
      }
      const data = await this.getKnowledgeRawText({ workspaceId: this.workspace.id, knowledgeId: file.id });
      this.openedFile = data;
      this.openFile = true;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    },
    async onFileDelete(id) {
      await this.deleteWorkspaceKnowledge({ workspaceId: this.workspace.id, knowledgeId: id })
    },
    async onFileChange(events) {
      for(let thisFile of events) {
        if(this.validateSize(thisFile)) {
          this.$store.dispatch('notifications/setError',`Knowledge size limit exceeded`)
          return;
        }
        const base64 =  await this.toBase64(thisFile)
        await this.addWorkspaceKnowledge(
            {
              files: [{
                workspaceId: this.workspace.id,
                base64: base64,
                type: thisFile.type,
                name: thisFile.name,
                size: thisFile.size,
              }],
              workspaceId: this.workspace.id,
            }
        )
      }
    },
    includeNow(value) {
      let jsonKnowledgeData = this.jsonKnowledge;
      jsonKnowledgeData ??= {};
      if(typeof jsonKnowledgeData === 'string') {
        jsonKnowledgeData = JSON.parse(jsonKnowledgeData);
      }
      jsonKnowledgeData['now'] = value;
      if(value) {
        jsonKnowledgeData['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else {
        delete jsonKnowledgeData['timezone'];
      }
      this.jsonKnowledge = cloneDeep(jsonKnowledgeData);
    },
    async updateMemory() {
      this.$emit('jsonKnowledgeChanged', this.jsonKnowledge)
      this.openJsonEditModal = false
      this.$emit('close');
    },
    async updateWorkspaceInfo() {
      if(this.workspace?.id && !this.workspaceLoading) {
        await this.updateWorkspace({
          workspaceId: this.workspace.id,
          knowledgeBaseSummary: this.knowledgeBaseSummary,
          general_actions: this.workspace.general_actions,
        });
      }
    },
    async updateWorkspaceKBSettings() {
      if(this.workspace?.id && !this.workspaceLoading) {
        await this.updateWorkspace({
          workspaceId: this.workspace.id,
          knowledgeBaseAgentModel: this.selectedModel,
          knowledgeBaseContextSize: this.workspace.knowledgeBaseContextSize,
          knowledgeBaseTemperature: this.workspace.knowledgeBaseTemperature,
        });
      }
    },
    canEdit() {
      return this?.user?.is_privileged;
    },
  },
  mounted() {
    if(!this.jsonKnowledge) {
      this.jsonKnowledge = {
        now: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
    if(!this.jsonKnowledge.now) {
      this.isNowSet = false;
    }

    if(this.workspace?.id) {
      const kbAction = this.workspace?.general_actions?.find((act) => act?.action_type === 'internal_knowledge_action');
      if(kbAction) {
        this.knowledgeBaseSummary = kbAction?.settings?.summary;
      }
      this.selectedModel = this.workspace.knowledgeBaseAgentModel;
    }
  },
  created() {
    if(this?.workspace?.id) {
      this.getWorkspaceKnowledge({ workspaceId: this.workspace.id });
      const kbAction = this.workspace?.general_actions?.find((act) => act?.action_type === 'internal_knowledge_action');
      if(kbAction) {
        this.knowledgeBaseSummary = kbAction?.settings?.summary;
      }
      this.selectedModel = this.workspace.knowledgeBaseAgentModel;
    }
    if(!this.jsonKnowledge) {
      this.jsonKnowledge = {
        now: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
    if(!this.jsonKnowledge.now) {
      this.isNowSet = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'advanced-knowledge';
</style>
