<template>
  <div></div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions: credentialsActions } =
  createNamespacedHelpers('credentials');

export default {
  name: 'LoadAuth',
  props: {
    authType: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchAuthentications({ type: this.authType });
  },
  methods: {
    ...credentialsActions(['fetchAuthentications']),
  },
};
</script>
