<template>
  <div>
    <div>
      <v-autocomplete
          class="mb-3"
          outlined
          dense
          :value="settings.location"
          @input="(val) => setLocation(val)"
          item-text="name"
          item-value="id"
          :items="driveFolders"
          label="File Location"
          @click:append-outer="listFolders"
          :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
          clearable
      ></v-autocomplete>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions: worklfowActions } =
    createNamespacedHelpers('workflows/list');
const { mapGetters: workspacesGetters } = createNamespacedHelpers('aiWorkspace');

export default {
  name: 'OneDriveDownloadFile',
  components: {},
  props: {},
  data() {
    return {
      driveFolders: [],
      loading: false,
    };
  },
  computed: {
    ...workspacesGetters({
      selectedAction: 'SELECTED_ACTION',
      generalActions: 'WORKSPACE_GENERAL_ACTIONS'
    }),
    settings() {
      return this.selectedAction.settings;
    },
    action() {
      return this.selectedAction.action_type;
    },
    suite() {
      return 'outlook';
    },
  },
  created() {
    if (!this.settings.location || !this.settings.locationName) {
      return;
    }
    this.driveFolders.push({
      id: this.settings.location,
      name: this.settings.locationName,
    });
  },
  methods: {
    ...worklfowActions(['outlookAction']),
    setLocation(location) {
      this.onUpdate(location, 'settings.location');
      const folder = this.driveFolders.find((item) => item.id === location);
      if (folder?.name) {
        this.onUpdate(folder.name, 'settings.locationName');
        this.onUpdate(folder.driveId, 'settings.driveId');
      } else {
        this.onUpdate(null, 'settings.locationName');
        this.onUpdate(null, 'settings.driveId');
      }
    },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    suiteAction(data) {
      return this.outlookAction(data);
    },
    async listFolders() {
      if (!this.settings.authenticationId) {
        return;
      }
      this.loading = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_folders',
        };
        const resp = await this.suiteAction(data);

        if (resp && resp.result) {
          this.driveFolders = resp.result;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
