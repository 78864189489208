<template>
  <div>
    <LoadAuth authType="pVerify" />
  </div>
</template>

<script>
import LoadAuth from '@/views/ai-workspaces/workspace/general-actions/action-settings/actions/load-auth.vue';

export default {
  name: 'PverifyGetBenefits',
  components: {
    LoadAuth,
  },
};
</script>
