<template>
  <div class="AwsClaudeSettings mt-1">
    <v-select
        outlined
        dense
        v-model="settings.useWorkspace"
        :items="workspaces.map(workspace => ({ label: workspace.name, value: workspace.id }))"
        @change="onSelectWorkspace"
        item-text="label"
        label="Agent"
        clearable
        @option:clear="onWorkspaceClear"
        style="margin-bottom: 15px"
    ></v-select>
    <mustache-template
        label="Action Description"
        class="mr-2"
        :value="settings.overrideDescription"
        @input="(val) => onUpdate(val, `settings.overrideDescription`)"
        :suggestions="[]"
        :singleLine="false"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { getModel } from '@/util/actionsModels';
import 'vue-json-viewer/style.css';
import MustacheTemplate from "@/components/mustache/mustache-template.vue";

const {
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');

const { mapGetters: workspacesGetters, mapActions: workspaceActions } = createNamespacedHelpers('aiWorkspace');

export default {
  name: 'TalkToAgentSettings',
  components: {MustacheTemplate},
  data() {
    return {
      showDialog: false,
      showPromt: '',
      error: '',
      output: '',
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        {
          label: 'Claude 3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
        {
          label: 'Claude 3.5 Sonnet v2',
          value: 'anthropic.claude-3-5-sonnet-20241022-v2:0'
        },
        {
          label: 'Claude 3.7 Sonnet',
          value: 'us.anthropic.claude-3-7-sonnet-20250219-v1:0'
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
        },
        {
          label: 'Claude V3.5 Haiku',
          value: 'anthropic.claude-3-5-haiku-20241022-v1:0'
        },
      ],
    };
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.fetchPromts();
    },
  },
  computed: {
    ...workspacesGetters({
      workspaces: 'WORKSPACES_LIST',
      workspacesLoading: 'WORKSPACE_LIST_LOADING',
      selectedAction: 'SELECTED_ACTION',
      generalActions: 'WORKSPACE_GENERAL_ACTIONS'
    }),
    settings() {
      return this.selectedAction.settings;
    },
    showAddParamBtn() {
      const params = this.settings.params;
      return (
        !params ||
        !params[0] ||
        (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
  },
  mounted() {
    this.getWorkspaces();
  },
    methods: {
    ...workspaceActions(['getWorkspaces', 'getWorkspace']),
    ...workflowDetailsActions(['executeSingleAction']),
      deleteTool(i) {
        const settings = cloneDeep(this.settings);
        settings.tools.splice(i, 1);
        if (!settings.tools.length) delete settings.tools;

        this.onUpdate(settings, 'settings');
      },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
      async onSelectWorkspace(id) {
        if(!id) {
          this.onUpdate(id, `settings.useWorkspace`);
          this.onUpdate(null, `settings.model`);
          this.onUpdate(null, `settings.systemPromptId`);
          this.onUpdate(null, `settings.systemPrompt`);
          this.onUpdate(null, `settings.tools`);
          return;
        }
        this.onUpdate(id, `settings.useWorkspace`);
        const workspace = await this.getWorkspace({ workspaceId: id, skipLoading: true })
        this.onUpdate(workspace.tools || [], `settings.tools`);
        this.onUpdate(workspace.model, `settings.model`);
        this.onUpdate(workspace?.SystemPrompt?.id, `settings.systemPromptId`);
        this.onUpdate(workspace?.SystemPrompt?.prompt, `settings.systemPrompt`);
        this.selectedModel = this.models.find((model) => model.value === workspace.model)
      },
      onWorkspaceClear() {
        this.onUpdate(null, `settings.useWorkspace`);
      },
    deleteParam(index) {
      const settings = cloneDeep(this.settings);
      settings.promptInputs.splice(index, 1);
      if (!settings.promptInputs.length) delete settings.promptInputs;

      this.onUpdate(settings, 'settings');
    },
    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;
        const params = {};
        if (this.settings.params) {
          this.settings.params.forEach(
            (param) => (params[param.name] = param.value)
          );
        }

        const options = {
          data: {
            script: this.settings.script,
            params: JSON.stringify(params),
            test_mode: true,
          },
          action: this.selectedAction.action_type || 'execute_javascript',
        };
        const data = await this.executeSingleAction(options);
        if (data.success) {
          this.output = data.result || 'no output';
          const output_type = getModel(data.model);
          this.onUpdate(output_type, 'output_type');
        } else {
          throw Error(data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './talk-to-agent';
</style>
