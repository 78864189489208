<template>
  <div class="Workspace">
    <header class="Workspace__header">
      <div
        class="Workspace__header-container d-flex justify-space-between align-center"
      >
        <div class="d-flex align-center">
          <p class="Workspace__header-title">
            <router-link
              to="/agents"
              tag="a"
              class="Workspace__header-title-link"
            >
              Agents
            </router-link>
            <v-icon small class="Workspace__header-title-icon">
              mdi-chevron-right
            </v-icon>
            <span class="Workspace__header-title-name">
              {{ workspace.name }}
            </span>
          </p>
        </div>
      </div>
    </header>

    <v-col v-if="!workspaceLoading">
      <div style="padding: 25px; padding-bottom: 0px; padding-top: 10px">
        <div class="Workspace__settings">
          <div class="section">
            <span style="font-size: 14pt; margin-bottom: 15px">
              {{ workspace.description }}
            </span>
            <div class="line"></div>
          </div>
          <div class="section">
            <div class="content">
              <div class="inline">
                <div class="inlineItem">
                  <v-row style="display: flex">
                    <v-col style="flex-grow: 1">
                      <v-textarea
                        :disabled="!canEdit()"
                        class="TextAreaSystemPrompt"
                        outlined
                        dense
                        :placeholder="'System Prompt'"
                        hide-details="auto"
                        novalidate
                        :rows="3"
                        v-model="systemPromptText"
                        @change="() => saveSystemPrompt()"
                        label="System Prompt"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </div>
                <div class="inlineItem">
                  <v-autocomplete
                      :disabled="!canEdit()"
                      style="width: 250px; border-radius: 15px;"
                      outlined
                      dense
                      label="AI Model"
                      :items="models"
                      v-model="selectedModel"
                      item-text="label"
                      item-value="value"
                      @change="() => { updateWorkspaceInfo() }"
                  ></v-autocomplete>
                  <v-switch
                    v-if="
                      selectedModel ===
                      'us.anthropic.claude-3-7-sonnet-20250219-v1:0'
                    "
                    label="Extended Mode"
                    v-model="workspace.modelThinking"
                    @change="updateWorkspaceInfo()"
                  >
                  </v-switch>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="content">
              <div class="inline">
                <div class="inlineItem">
                  <div class="maxTokens">
                    <span style="margin-left: 7px">
                      Number of latest included messages
                    </span>
                    <v-slider
                      v-model="historyLimit"
                      @change="
                        () => {
                          updateWorkspaceInfo();
                        }
                      "
                      :min="0"
                      :max="50"
                      :disabled="includeAllHistory || !canEdit()"
                    >
                      <template v-slot:append>
                        <span>
                          {{ historyLimit }}
                        </span>
                      </template>
                    </v-slider>
                  </div>
                  <v-checkbox
                    v-if="isShAdmin() && canEdit()"
                    label="Include All"
                    v-model="includeAllHistory"
                    style="margin-top: -15px"
                    @change="
                      () => {
                        updateWorkspaceInfo();
                      }
                    "
                  >
                  </v-checkbox>
                </div>
                <div class="inlineItem">
                  <div class="maxTokens">
                    <span> Max Tokens </span>
                    <v-slider
                      @change="
                        () => {
                          updateWorkspaceInfo();
                        }
                      "
                      :disabled="!canEdit()"
                      v-model="workspace.maxTokens"
                      :min="1000"
                      :max="15000"
                    >
                      <template v-slot:append>
                        <span>
                          {{ workspace.maxTokens }}
                        </span>
                      </template>
                    </v-slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="title">
              Custom Actions
              <v-btn
                style=""
                v-if="canEdit()"
                icon
                @click.stop="isToolSelectDialog = true"
              >
                <v-icon small> mdi-pencil-outline </v-icon>
              </v-btn>
            </div>
            <div class="content">
              <div class="selectedToolsList">
                <div
                  class="selectedToolItem"
                  v-for="tool in workspace.tools"
                  :key="tool.id"
                >
                  {{ tool.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="content">
              <general-actions />
            </div>
          </div>
          <div class="section" style="margin-bottom: 45px">
            <div class="title">Agent Knowledge</div>
            <div>
              <v-switch
                v-model="workspace.advancedKnowledgeBase"
                @change="() => updateWorkspaceInfo()"
                color="primary"
                label="Advanced Knowledge Base"
              ></v-switch>
            </div>
            <div class="content">
              <workspace-knowledge
                v-if="!workspace.advancedKnowledgeBase"
                :json-knowledge="workspace.jsonKnowledge"
                @jsonKnowledgeChanged="
                  (data) => {
                    workspace.jsonKnowledge = data;
                    updateWorkspaceInfo();
                  }
                "
              />
              <advanced-agent-knowledge
                v-if="workspace.advancedKnowledgeBase"
                :json-knowledge="workspace.jsonKnowledge"
                @jsonKnowledgeChanged="
                  (data) => {
                    workspace.jsonKnowledge = data;
                    updateWorkspaceInfo();
                  }
                "
              />
            </div>
          </div>
          <div class="section" v-if="canEdit()">
            <div class="line"></div>
            <div class="content">
              <div class="inline">
                <div class="inlineItem_near">
                  <v-btn
                    style=""
                    icon
                    @click.stop="
                      () => {
                        workspace.private = !workspace.private;
                        updateWorkspaceInfo();
                      }
                    "
                  >
                    <v-icon big>
                      {{
                        workspace.private
                          ? 'mdi-lock'
                          : 'mdi-lock-open-variant-outline'
                      }}
                    </v-icon>
                  </v-btn>
                  <span>{{ workspace.private ? 'Private' : 'Public' }}</span>
                </div>
                <div class="inlineItem_near">
                  <v-btn
                    style=""
                    icon
                    @click.stop="isParticipantDialogOpen = true"
                  >
                    <v-icon big> mdi-account-supervisor-circle </v-icon>
                  </v-btn>
                  <span> Participants </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; padding: 45px">
        <div style="flex-grow: 0">
          <v-row class="Workspace__messageInput">
            <v-col>
              <v-textarea
                class="TextArea"
                outlined
                dense
                hide-details="auto"
                novalidate
                :rows="1"
                v-model="message"
                id="message_input2"
                @keydown.enter.prevent=""
                :loading="sendMessageLoading"
                label="Message"
              >
              </v-textarea>
              <v-row
                class="Workspace__files"
                v-if="filesAccumulated.length !== 0"
              >
                <div class="Workspace__filesContainer">
                  <div
                    v-for="(file, index) in filesAccumulated"
                    :key="index"
                    class="fileItem"
                  >
                    <div
                      style="
                        position: relative;
                        display: flex;
                        justify-content: start;
                      "
                    >
                      <button
                        @click.stop="onFileDelete(index)"
                        class="closeButton"
                      >
                        <span>&times;</span>
                      </button>
                      <img
                        v-if="file.type.includes('image')"
                        :src="createObjectURL(file)"
                        class="image"
                      />
                      <div style="height: 80px">
                        <img
                          v-if="!file.type.includes('image')"
                          class="document"
                          :src="`${publicPath}/icons/file-icon.svg`"
                        />
                      </div>
                      <span style="top: 0px">
                        {{ file.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-row>
            </v-col>
            <v-col style="max-width: 90px">
              <v-row style="display: flex; margin-top: 0px">
                <v-btn icon @click.stop="send()">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
                <v-file-input
                  style="margin-top: -12px"
                  label="File input"
                  hide-input
                  v-model="files"
                  multiple
                  accept=".doc,.docx,.pdf,.csv,.txt,.html,.odt,.rtf,.epub,.jpeg,.png,.jpg,image/*"
                  @change="onFileChange"
                ></v-file-input>
              </v-row>
            </v-col>
          </v-row>
          <v-row style="margin-left: 0; display: flex; flex-flow: row">
            <workspace-prompt
              @onPromptSelect="
                (data) => {
                  this.message = data.prompt;
                }
              "
            />
            <v-btn
              v-if="(message?.trim()?.replaceAll('\n', '') || '') !== ''"
              @click.stop="saveWorkspacePrompt"
              :loading="workspaceActionLoading"
              style="
                width: 100px;
                font-size: 8pt;
                background: #ddf0ff;
                margin-top: 10px;
                border: 1px #43c1f8 solid;
              "
              outlined
              dense
              name="Save Prompt"
              label="Save Prompt"
            >
              Save Prompt
            </v-btn>
          </v-row>
        </div>
      </div>

      <v-row class="Workspace__sessions">
        <div class="titleWrapper">
          <span class="title"> Your sessions </span>
        </div>
        <div class="list">
          <div
            class="item"
            v-for="session in sessions"
            :key="session.id"
            @click="goToSession(session.id)"
          >
            <span>
              {{ session.name }}
            </span>
            <span>
              {{ getTime(session.createdAt) }}
            </span>
          </div>
        </div>
      </v-row>

      <tools-select
        :dialog="isToolSelectDialog"
        :toolSets="workspace.tools"
        @close="isToolSelectDialog = false"
        @toolsChanged="
          (data) => {
            workspace.tools = data;
            updateWorkspaceInfo();
          }
        "
      />
      <workspace-participant
        :dialog="isParticipantDialogOpen"
        @close="isParticipantDialogOpen = false"
      />
    </v-col>
    <v-col v-if="workspaceLoading">
      <Loader></Loader>
    </v-col>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
  mapMutations: AIChatMutations,
} = createNamespacedHelpers('AIChat');
const {
  mapGetters: workspacesGetters,
  mapActions: workspaceActions,
  mapMutations: workspacesMutations,
} = createNamespacedHelpers('aiWorkspace');

import debounce from 'lodash/debounce';

import * as moment from 'moment';
import ToolsSelect from '@/views/ai-workspaces/create-workspace/tools-select/tools-select.vue';
import Loader from '@/components/loader/loader.vue';
import lodashGet from 'lodash/get';
import constants from '@/util/constants';
import WorkspaceParticipant from '@/views/ai-workspaces/workspace/participants/participants.vue';
import WorkspaceKnowledge from '@/views/ai-workspaces/workspace/knowledge/workspace-knowledge.vue';
import WorkspacePrompt from '@/views/ai-workspaces/workspace/workspace-prompts/workspace-prompts.vue';
import GeneralActions from '@/views/ai-workspaces/workspace/general-actions/general-actions.vue';
import AdvancedAgentKnowledge from '@/views/ai-workspaces/workspace/knowledge/advanced-knowledge/advanced-knowledge.vue';

export default {
  name: 'AgentDetails',
  components: {
    AdvancedAgentKnowledge,
    GeneralActions,
    WorkspacePrompt,
    WorkspaceKnowledge,
    WorkspaceParticipant,
    ToolsSelect,
    Loader,
  },
  data() {
    return {
      isWorkspaceKnowledgeOpen: false,
      memory: {
        now: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      message: '',
      publicPath: constants.isDevelopment ? '' : '',
      includeAllHistory: false,
      isToolSelectDialog: false,
      isParticipantDialogOpen: false,
      historyLimit: 15,
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0',
        },
        {
          label: 'Claude 3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0',
        },
        {
          label: 'Claude 3.5 Sonnet v2',
          value: 'anthropic.claude-3-5-sonnet-20241022-v2:0',
        },
        {
          label: 'Claude 3.7 Sonnet',
          value: 'us.anthropic.claude-3-7-sonnet-20250219-v1:0',
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0',
        },
        {
          label: 'Claude V3.5 Haiku',
          value: 'anthropic.claude-3-5-haiku-20241022-v1:0',
        },
      ],
      selectedModel: 'anthropic.claude-3-haiku-20240307-v1:0',
      files: [],
      filesAccumulated: [],
      selectedSystemPrompt: null,
      systemPromptText: '',
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
      user: 'USER',
    }),
    ...AIChatGetters({
      systemPrompts: 'SYSTEM_PROMPTS',
      systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
      updateSystemPromptLoading: 'UPDATE_SYSTEM_PROMPT_LOADING',
      sendMessageLoading: 'SEND_MESSAGE_LOADING',
    }),
    ...workspacesGetters({
      workspace: 'WORKSPACE',
      sessions: 'WORKSPACE_SESSIONS',
      updateWorkspaceLoading: 'WORKSPACE_UPDATE_LOADING',
      workspaceLoading: 'WORKSPACE_LOADING',
      sessionsLoading: 'WORKSPACE_SESSIONS_LOADING',
      workspaceActionLoading: 'IS_WORKSPACE_PROMPTS_ACTION_LOADING',
      selectedPrompt: 'SELECTED_WORKSPACE_PROMPT',
      workspacePrompts: 'WORKSPACE_PROMPTS',
    }),
  },
  watch: {
    'selectedCustomer.customer_id'() {
      //this.getInfo();
    },
  },
  methods: {
    ...AIChatActions([
      'sendMessage',
      'getToolSets',
      'getSystemPrompts',
      'updateSystemPrompt',
      'createSystemPrompt',
    ]),
    ...AIChatMutations({
      setSendMessageLoading: 'SET_SEND_MESSAGE_LOADING',
      setSendMessage: 'SET_SEND_MESSAGE',
      setChatWorkspace: 'SET_CHAT_WORKSPACE',
    }),
    ...workspacesMutations({
      pushToWorkspacePrompt: 'PUSH_TO_WORKSPACE_PROMPTS',
      setWorkspacePrompts: 'SET_WORKSPACE_PROMPTS',
      setWorkspaceLoading: 'SET_WORKSPACE_LOADING',
    }),
    ...workspaceActions([
      'getWorkspace',
      'getWorkspaceSessions',
      'updateWorkspace',
      'createWorkspacePrompt',
      'updateWorkspacePrompt',
    ]),
    async saveWorkspacePrompt() {
      if (this.selectedPrompt?.id) {
        const prompt = await this.updateWorkspacePrompt({
          prompt: this.message,
          workspaceId: this.workspace.id,
          workspacePromptId: this.selectedPrompt?.id,
        });
        const prompts = [...this.workspacePrompts];
        const index = prompts.findIndex((item) => item?.id === prompt?.id);
        prompts[index] = prompt;
        this.setWorkspacePrompts([...prompts]);
        return;
      }
      const prompt = await this.createWorkspacePrompt({
        prompt: this.message,
        workspaceId: this.workspace.id,
      });
      this.pushToWorkspacePrompt(prompt);
    },
    createObjectURL(file) {
      return URL.createObjectURL(file);
    },
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },
    async saveSystemPrompt() {
      if (this.selectedSystemPrompt?.id) {
        await this.updateSystemPrompt({
          systemPromptId: this.selectedSystemPrompt.id,
          prompt: this.systemPromptText,
        });
        return;
      }
      const prompt = await this.createSystemPrompt({
        prompt: this.systemPromptText,
        name: this.systemPromptText,
      });
      this.systemPrompts.push(prompt);
      this.selectedSystemPrompt = prompt;
      this.systemPromptText = prompt.prompt;
    },
    async updateSelectedSystemPrompt(value) {
      const systemPrompt = this.systemPrompts.find(
        (prompt) => prompt.id === value
      );
      this.systemPromptText = systemPrompt.prompt;
      this.selectedSystemPrompt = systemPrompt;
    },
    getTime(date) {
      return moment(date).fromNow();
    },
    onFileDelete(index) {
      this.filesAccumulated.splice(index, 1);
      this.files = [];
    },
    onFileChange(event) {
      event.forEach((thisFile) => {
        if (this.validateSize(thisFile)) {
          this.$store.dispatch(
            'notifications/setError',
            `${thisFile.name} too big`
          );
          return;
        }
        this.filesAccumulated.push(thisFile);
      });
      this.files = [];
    },
    async prepareFiles() {
      const prepared = await Promise.all(
        this.filesAccumulated.map(async (file) => {
          const base64 = await this.toBase64(file);
          return {
            base64: base64,
            name: file.name,
            type: file.type,
          };
        })
      );
      return prepared;
    },
    validateSize(input) {
      const fileSize = input.size / 1024 / 1024; // in MiB
      return fileSize > 1;
    },
    debounceInput: debounce(function (e) {
      this.search = e;
    }, 1000),
    async getInfo(workspaceId) {
      this.setSendMessageLoading(false);
      await this.getWorkspace({ workspaceId });
      this.selectedSystemPrompt = this.workspace?.SystemPrompt;
      this.systemPromptText = this.selectedSystemPrompt?.prompt;
      this.selectedModel = this.models.find(
        (model) => model.value === this.workspace.model
      ).value;
      this.historyLimit = this.workspace.historyLimit;
      this.includeAllHistory =
        this.workspace.historyLimit === null ||
        this.workspace.historyLimit === undefined;
      this.setChatWorkspace(this.workspace);
      await this.getWorkspaceSessions({ workspaceId });
      await this.getSystemPrompts();
      this.setWorkspaceLoading(false);
    },
    async updateWorkspaceInfo() {
      if (this.workspace?.id && !this.workspaceLoading) {
        await this.updateWorkspace({
          workspaceId: this.workspace.id,
          toolSetIds: this.workspace.tools.map((tool) => tool.id),
          systemPromptId: this.selectedSystemPrompt?.id,
          model: this.selectedModel,
          name: this.workspace.name,
          maxTokens: this.workspace.maxTokens,
          historyLimit: this.includeAllHistory ? null : this.historyLimit,
          private: this.workspace.private,
          jsonKnowledge: this.workspace.jsonKnowledge,
          advancedKnowledgeBase: this.workspace.advancedKnowledgeBase,
          modelThinking: this.workspace.modelThinking,
        });
      }
    },

    async send() {
      if (this.message === '') {
        return;
      }
      const messageValue = this.message.valueOf();
      this.message = '';
      this.setSendMessageLoading(true);

      this.setChatWorkspace(this.workspace);
      this.setSendMessage({
        workspaceId: this.workspace.id,
        workspace: this.workspace,
        files: this.filesAccumulated,
        messageValue,
        memory: this.memory,
      });

      this.$router.push({ path: `/ai-chat` });
    },

    goToSession(id) {
      this.$router.push({ path: `/ai-chat`, query: { session: id } });
    },

    canEdit() {
      return this?.user?.is_privileged;
    },
  },
  async beforeDestroy() {
    this.setWorkspaceLoading(true);
  },
  async mounted() {
    const { workspaceId } = this.$route.params;
    await this.getInfo(workspaceId);
    document
      .getElementById('message_input2')
      .addEventListener('keydown', async (e) => {
        const getCaret = (el) => {
          if (el.selectionStart) {
            return el.selectionStart;
          } else if (document.selection) {
            el.focus();
            const r = document.selection.createRange();
            if (r == null) {
              return 0;
            }
            const re = el.createTextRange(),
              rc = re.duplicate();
            re.moveToBookmark(r.getBookmark());
            rc.setEndPoint('EndToStart', re);
            return rc.text.length;
          }
          return 0;
        };

        const start = e.target.selectionStart;
        const end = e.target.selectionEnd;
        const caret = getCaret(e.target);
        if (
          e.key == 'Enter' &&
          e.ctrlKey == false &&
          e.shiftKey == false &&
          this.message.replaceAll('\n', '') !== ''
        ) {
          await this.send();
        }
        if (e.key == 'Enter' && e.ctrlKey == true) {
          this.message =
            this.message.substring(0, caret) +
            '\n' +
            this.message.substring(end);
          e.stopPropagation();
          setTimeout(() => {
            e.target.selectionStart = e.target.selectionEnd = start + 1;
          }, 1);
        }
        if (e.key == 'Enter' && e.shiftKey == true) {
          this.message =
            this.message.substring(0, caret) +
            '\n' +
            this.message.substring(end);
          e.stopPropagation();
          setTimeout(() => {
            e.target.selectionStart = e.target.selectionEnd = start + 1;
          }, 1);
        }
      });
    document.getElementById('message_input2').onpaste = (event) => {
      const items = (
        event?.clipboardData || event?.originalEvent?.clipboardData
      ).items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          this.filesAccumulated ??= [];
          this.filesAccumulated.push(blob);
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@import './workspace';
</style>
