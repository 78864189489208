import moment from 'moment';

export const helpers = {
  methods: {
    dateFormat(date) {
      // CRUTCH: UTC timezone is set for effective isurance date (from DB)
      // If method will be used for anything else: be careful
      let formatedDate = moment(date).utc();
      if (!formatedDate.isValid()) {
        return null;
      }

      return formatedDate.format('MM/DD/YYYY');
    },
    priceFormat(num) {
      let number = Number(num);

      let p = number.toFixed(2).split('.');

      let main = p[0]
        .split('')
        .reverse()
        .reduce(function (acc, num, i) {
          return num == '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc;
        }, '');

      if (number < 0) {
        main = '-' + main;
      }

      return p[1] == '00' ? main : main + '.' + p[1];
    },
    amountFormat(num) {
      return Number(num).toFixed(2);
    },
    disableInvoiceActionForGreenwayPostpay(
      customer,
      selectedCustomer,
      invoice_type
    ) {
      const greenwayCustomers = [1, 2, 4];
      let customer_id = customer.customer_id;
      if (customer_id === 0) {
        customer_id = selectedCustomer.customer_id;
      }

      if (
        greenwayCustomers.includes(customer_id) &&
        invoice_type !== 'prePay'
      ) {
        return true;
      }
      return false;
    },
    isNullOrUndefined(data) {
      if (data === null || data === undefined) {
        return true;
      }
      return false;
    },
  },
};
