import { render, staticRenderFns } from "./get-folder.vue?vue&type=template&id=6211f9d6&scoped=true"
import script from "./get-folder.vue?vue&type=script&lang=js"
export * from "./get-folder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6211f9d6",
  null
  
)

export default component.exports