var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"AttachDialog",attrs:{"value":_vm.dialog,"persistent":"","max-width":"1000"},on:{"click:outside":function($event){return _vm.$emit('close')}}},[_c('v-card',{staticClass:"AttachDialog__card"},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Attach from OneDrive ")]),_c('v-col',[_c('v-row',[_c('v-switch',{attrs:{"label":"Use share URL"},model:{value:(_vm.isShareUrl),callback:function ($$v) {_vm.isShareUrl=$$v},expression:"isShareUrl"}},[_vm._v(" Use Share URL ")])],1),_c('v-row',{staticClass:"AttachDialog__auth_settings"},[_c('v-autocomplete',{staticClass:"mb-n4",attrs:{"outlined":"","dense":"","items":_vm.authentications,"item-text":"name","item-value":"id","label":"Authentication","append-outer-icon":_vm.locationLoading ? 'mdi-loading mdi-spin' : 'mdi-reload',"clearable":""},on:{"click:append-outer":_vm.getAuth},model:{value:(_vm.authentication),callback:function ($$v) {_vm.authentication=$$v},expression:"authentication"}})],1),(_vm.isShareUrl)?_c('v-row',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"URL"},model:{value:(_vm.shareUrl),callback:function ($$v) {_vm.shareUrl=$$v},expression:"shareUrl"}})],1):_vm._e(),(!_vm.isShareUrl)?_c('v-row',{staticClass:"AttachDialog__file_settings"},[_c('v-autocomplete',{staticClass:"mb-3",attrs:{"return-object":"","outlined":"","dense":"","value":_vm.fileName,"item-text":"name","item-value":"id","items":_vm.driveFiles,"label":"File","append-outer-icon":_vm.locationLoading ? 'mdi-loading mdi-spin' : 'mdi-reload',"clearable":""},on:{"click:append-outer":_vm.listFiles},scopedSlots:_vm._u([{key:"item",fn:function({ props, item }){return [_c('v-list-item-content',_vm._b({attrs:{"subtitle":item.mimeType,"title":item.name}},'v-list-item-content',props,false),[_c('div',_vm._b({staticStyle:{"display":"flex","flex-flow":"row"}},'div',props,false),[_c('div',{staticClass:"AttachDialog_itemIcon"},[(
                      item.mimeType.includes('document') &&
                      !item.mimeType.includes('sheet')
                    )?_c('document-icon',{staticClass:"icon",staticStyle:{"width":"20px","height":"20px"}}):_vm._e(),(
                      item.mimeType.includes('spreadsheet') ||
                      item.mimeType.includes('sheet')
                    )?_c('spreadsheetIcon',{staticClass:"icon",staticStyle:{"width":"20px","height":"20px"}}):_vm._e()],1),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])])]}}],null,false,2050632433),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.createKnowledge}},[_vm._v(" Add ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }