<template>
  <div>
    <div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters: workspacesGetters } = createNamespacedHelpers('aiWorkspace');

export default {
  name: 'OneDriveGetFolder',
  components: {},
  props: {},
  data() {
    return {
      driveFolders: [],
      loading: false,
    };
  },
  computed: {
    ...workspacesGetters({
      selectedAction: 'SELECTED_ACTION',
      generalActions: 'WORKSPACE_GENERAL_ACTIONS'
    }),
    settings() {
      return this.selectedAction.settings;
    },
    action() {
      return this.selectedAction.action_type;
    },
    suite() {
      return 'outlook';
    },
  },
  created() {
    if (!this.settings.location || !this.settings.locationName) {
      return;
    }
    this.driveFolders.push({
      id: this.settings.location,
      name: this.settings.locationName,
    });
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<style lang="scss" scoped></style>
