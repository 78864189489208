<template>
  <div class="GoogleSettings mt-1">
    <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.search"
        @input="(val) => onUpdate(val, 'settings.search')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Search Query"
        clearable
    ></v-select>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import 'vue-json-viewer/style.css';
import { helpers } from '@/mixins/helpers';

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');

export default {
  name: 'google-search-settings',
  components: {},
  props: {
    availableInputFields: {required: true},
  },
  mixins: [helpers],
  data() {
    return {
      newInput: undefined,
      testOutput: null,
      showDialog: false,
      error: '',
      output: '',
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<style scoped lang="scss">
@import './google-settings';
</style>
